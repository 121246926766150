import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ProjectDetails from "./ProjectDetails"
import SEO from "./seo"

export default function UserProjectDetails({ data, location }) {
  const { project } = data
  return (
    <Layout location={location}>
      <SEO title={`Prenez rendez-vous chez ${project.name}`} />
      <ProjectDetails data={project} context="details" flag={true} />
    </Layout>
  )
}
export const query = graphql`
  query($id: String!) {
    project(id: { eq: $id }) {
      id
      slug
      name
      canShip
      planCanVisio
      meta {
        address
        city
        zip_code
        phoneNumber
        catalog_url
        url
        shopUrl
        description
        fullDescription
        background_url
        logo_url
      }
      background_url {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 2.9)
        }
      }
      logo_url {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1)
        }
      }
      # cgv {
      #   email
      # }
      # policy {
      #   email
      # }
      cgv_url {
        publicURL
      }
      policy_url {
        publicURL
      }
      catalog_url {
        publicURL
      }
    }
  }
`
