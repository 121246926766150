import React from "react"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Grow from "@material-ui/core/Grow"
import Paper from "@material-ui/core/Paper"
import Popper from "@material-ui/core/Popper"
import MenuItem from "@material-ui/core/MenuItem"
import MenuList from "@material-ui/core/MenuList"
import { makeStyles } from "@material-ui/core/styles"
import {
  Avatar,
  Box,
  IconButton,
  ListItemIcon,
  SvgIcon,
  Typography,
} from "@material-ui/core"
import { Auth } from "aws-amplify"
import AccountCircleIcon from "@material-ui/icons/AccountCircle"
import PaymentIcon from "@material-ui/icons/Payment"
import { navigate } from "gatsby-link"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  paper: {
    padding: theme.spacing(2),
    ...theme.typography.h6,
  },
  menuItem: {
    padding: 0,
    marginTop: theme.spacing(2),
  },
  icon: {
    minWidth: 0,
    marginRight: theme.spacing(1),
    color: "rgb(33,33,33)",
  },
}))

export default function ProfileMenuTest({ cognitoUser, user }) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    prevOpen.current = open
  }, [open])

  const handleNavigation = path => () => {
    setOpen(false)
    navigate(`/dashboard/${path}`)
  }
  const { image, picture } = user || {}
  console.log({ image, picture })
  return (
    <div className={classes.root}>
      <div>
        <IconButton
          onClick={handleToggle}
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          style={{ paddingRight: 0, paddingLeft: 10 }}
        >
          <Avatar src={image || picture} />
          {/* <ProfileMenu /> */}
        </IconButton>
        {/* <Button
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          Toggle Menu Grow
        </Button> */}
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper className={classes.paper}>
                <ClickAwayListener onClickAway={handleClose}>
                  <Box>
                    <Typography variant="overline">Mon compte</Typography>
                    <MenuList
                      autoFocusItem={open}
                      id="menu-list-grow"
                      onKeyDown={handleListKeyDown}
                      style={{ margin: 0, padding: 0 }}
                    >
                      <MenuItem
                        onClick={handleNavigation("account")}
                        className={classes.menuItem}
                      >
                        <ListItemIcon className={classes.icon}>
                          <AccountCircleIcon fontSize="large" />
                        </ListItemIcon>
                        <Typography variant="h6">Profil</Typography>
                      </MenuItem>
                      {cognitoUser?.groups.includes("ShopsAdmin") ? (
                        <MenuItem
                          onClick={handleNavigation("billing")}
                          className={classes.menuItem}
                        >
                          <ListItemIcon className={classes.icon}>
                            <PaymentIcon fontSize="large" color="inherit" />
                          </ListItemIcon>
                          <Typography variant="h6">Plan</Typography>
                        </MenuItem>
                      ) : null}
                      <MenuItem
                        className={classes.menuItem}
                        onClick={e => {
                          handleClose(e)
                          Auth.signOut({ global: true })
                            .then(data => console.log(data))
                            .catch(err => {
                              console.log(err)
                            })
                        }}
                        style={{ color: "red" }}
                      >
                        <ListItemIcon
                          className={classes.icon}
                          style={{ color: "red" }}
                        >
                          <SvgIcon fontSize="large">
                            <g>
                              <path d="M0,0h24v24H0V0z" fill="none" />
                            </g>
                            <g>
                              <path d="M17,8l-1.41,1.41L17.17,11H9v2h8.17l-1.58,1.58L17,16l4-4L17,8z M5,5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h7v-2H5V5z" />
                            </g>
                          </SvgIcon>
                        </ListItemIcon>
                        <Typography variant="h6">Déconnexion</Typography>
                      </MenuItem>
                    </MenuList>
                  </Box>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  )
}
