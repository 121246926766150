import React from "react"
import MenuIcon from "@material-ui/icons/Menu"
import {
  AppBar as Bar,
  Box,
  IconButton,
  makeStyles,
  Slide,
  Toolbar,
  useScrollTrigger,
} from "@material-ui/core"
import { useSelector } from "react-redux"
import { selectCognitoUser } from "../app/cognitoUser"
import { Link } from "gatsby"
import useIsMobile from "../utils/useIsMobile"
import { useLocation } from "@reach/router"
import AppBarLogo from "./AppBarLogo"
import ProfileMenu from "./ProfileMenu"
import PrimaryButton from "./PrimaryButton"
import { selectUser } from "../app/user"
import { selectIsMobile } from "../app/isMobile"

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  toolbar: {
    backgroundColor: "white",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: theme.spacing(1),
    height: 56,
    alignItems: "center",
  },
  logo: {},
}))

export function HideOnScroll({ children, active = true }) {
  const trigger = useScrollTrigger({ threshold: 20 })
  if (active)
    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
    )
  return children
}

export default function AppBar({ showDrawer, drawerOpen, hideDrawer, noMenu }) {
  const cognitoUser = useSelector(selectCognitoUser)
  const user = useSelector(selectUser)
  const location = useLocation()
  const isMobile = useSelector(selectIsMobile)
  // const isMobile = useIsMobile()
  const classes = useStyles()
  const showSignout = !!cognitoUser // && location?.pathname.startsWith("/dashboard")
  const showProBar =
    location?.pathname.startsWith("/dashboard") ||
    location?.pathname.startsWith("/finalsignup")
  const showProAccess =
    !location?.pathname.startsWith("/dashboard") &&
    !location?.pathname.startsWith("/finalsignup")
  const showPublicAccess = location?.pathname.startsWith("/dashboard")

  console.log({ appBarIsMobile: isMobile, cognitoUser })
  return (
    <HideOnScroll active={!location.pathname.startsWith("/dashboard/booking/")}>
      <Bar position="fixed" className={classes.appBar}>
        <Toolbar
          className={classes.toolbar}
          style={showProBar ? { backgroundColor: "#092D5C" } : {}}
        >
          <Box display="flex" flexDirection="row" height="100%" flex={1}>
            {isMobile &&
            !noMenu &&
            showProBar &&
            !location.pathname.startsWith("/dashboard/booking/") ? (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={drawerOpen ? hideDrawer : showDrawer}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            ) : (
              <AppBarLogo showProBar={showProBar} isMobile={isMobile} />
            )}
            {isMobile &&
            !noMenu &&
            showProBar &&
            !location.pathname.startsWith("/dashboard/booking/") ? (
              <AppBarLogo isMobile={isMobile} showProBar={showProBar} />
            ) : null}
          </Box>

          <Box display="flex" flexDirection="row">
            {showProAccess && isMobile !== undefined && (
              <PrimaryButton
                component={Link}
                to="/dashboard"
                style={{
                  whiteSpace: "nowrap",
                  alignSelf: "center",
                  // height: buttonHeightValue(),
                  // width: buttonWidthValue(),
                }}
              >
                {isMobile ? "Pro" : "Accès commerçant"}
              </PrimaryButton>
            )}
            {showPublicAccess && isMobile !== undefined && (
              <PrimaryButton
                inverted
                component={Link}
                to="/"
                style={{
                  whiteSpace: "nowrap",
                  alignSelf: "center",
                  // height: buttonHeightValue(),
                  // width: buttonWidthValue(),
                }}
              >
                {isMobile ? "Public" : "Accès public"}
              </PrimaryButton>
            )}
            {/* {false && showSignout && (
              <IconButton
                style={{
                  color: showProBar ? "white" : "primary",
                  paddingRight: 0,
                }}
                onClick={() =>
                  Auth.signOut({ global: true })
                    .then(data => console.log(data))
                    .catch(err => console.log(err))
                }
              >
                <SvgIcon>
                  <g>
                    <path d="M0,0h24v24H0V0z" fill="none" />
                  </g>
                  <g>
                    <path d="M17,8l-1.41,1.41L17.17,11H9v2h8.17l-1.58,1.58L17,16l4-4L17,8z M5,5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h7v-2H5V5z" />
                  </g>
                </SvgIcon>
              </IconButton>
            )} */}
            {showSignout && (
              <ProfileMenu cognitoUser={cognitoUser} user={user} />
            )}
          </Box>
        </Toolbar>
      </Bar>
    </HideOnScroll>
  )
}
