import { Button } from "@material-ui/core"
import React from "react"

export default function PrimaryButton({ style, inverted, ...props }) {
  const { disabled } = props
  return (
    <Button
      variant={inverted ? "outlined" : "contained"}
      style={{
        ...style,
        ...(inverted ? { border: "2px solid #092D5C" } : {}),
        color: inverted ? "#092D5C" : "white",
        backgroundColor: inverted ? "white" : disabled ? "#092D5C" : "#092D5C",
      }}
      {...props}
    />
  )
}
