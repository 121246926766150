import { IconButton } from "@material-ui/core"
import React from "react"
import { navigate } from "gatsby"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"

export default function BackButton() {
  const onBackPress = () => navigate(-1)
  return (
    <IconButton
      onClick={onBackPress}
      style={{
        background: "white",
      }}
    >
      <ArrowBackIcon />
    </IconButton>
  )
}
