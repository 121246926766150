import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { Box } from "@material-ui/core"

const proSources = {
  false: "/logo-blanc.png",
  true: "/ideo-blanc.png",
  undefined: "/ideo-blanc.png",
}

const publicSources = {
  false: "/logo-couleur.png",
  true: "/ideo-bleu.png",
  undefined: "/ideo-bleu.png",
}

export default function AppBarLogo({ showProBar, isMobile }) {
  return (
    <Box style={{ height: "100%", overflow: "hidden" }}>
      <Link to={showProBar ? "/dashboard" : "/"}>
        <img
          src={showProBar ? proSources[isMobile] : publicSources[isMobile]}
          alt="logo"
          style={{
            height: "100%",
            objectFit: "contain",
            margin: 0,
          }}
        />
      </Link>
    </Box>
  )
}
