import { makeStyles } from "@material-ui/core"
import React from "react"
// import { useScrollPosition } from "../utils/useScrollPosition"

const useStyles = makeStyles(theme => ({
  footer: {
    display: "flex",
    padding: `1rem`,
    zIndex: theme.zIndex.drawer + 1,
    background: "white",
    borderTop: "1px solid",
    whiteSpace: "pre-wrap",
    flexShrink: 0,
    justifyContent: "space-between",
  },
}))

export default function Footer() {
  const classes = useStyles()
  // const [sticky, setSticky] = React.useState(false)

  // useScrollPosition(
  //   ({ prevPos, currPos }) => {
  //     const isShow = currPos.y > prevPos.y
  //     if (isShow !== sticky) setSticky(isShow)
  //   },
  //   [sticky]
  // )
  return (
    <footer className={classes.footer}>
      <div>
        {`${new Date().getFullYear()},`}{" "}
        <a href="https://www.ido-data.fr">ido-data</a>{" "}
      </div>
      <a
        href="https://www.herve.shop/politique-de-confidentialite-client/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Politique de confidentialité
      </a>
    </footer>
  )
}

// ;
